import {
	action,
	computed,
	observable,
}                                                          from 'mobx';
import {
	AbstractStore,
	ApiCollection,
	PagedCollection,
}                                                          from 'modelx-jts-common';
import ProjectModel                                        from '../models/ProjectModel';
import ProjectSubCategoryModel                             from '../models/ProjectSubCategoryModel';
import ProjectCategoryModel                                from '../models/ProjectCategoryModel';
import ProjectPoleModel                                    from '../models/ProjectPoleModel';
import CompanyModel                                        from '../models/CompanyModel';
import ExpenseGroupModel                                   from '../models/ExpenseGroupModel';
import ExpenseEventModel                                   from '../models/ExpenseEventModel';
import ExpensePurchaseOrderStepModel                       from '../models/ExpensePurchaseOrderStepModel';
import ExpensePaymentVoucherModel                          from '../models/ExpensePaymentVoucherModel';
import ExpenseHistoryModel                                 from '../models/ExpenseHistoryModel';
import AccountingDocumentExpenseModel                      from '../models/AccountingDocumentExpenseModel';
import AccountingDocumentModel                             from '../models/AccountingDocumentModel';
import ExpenseModel                                        from '../models/ExpenseModel';
import ExpenseMediaModel                                   from '../models/ExpenseMediaModel';
import AccountingDocumentMediaModel                        from '../models/AccountingDocumentMediaModel';
import UserApiCollection                                   from '../models/collections/UserApiCollection';
import ExpenseSupplierContactModel                         from '../models/ExpenseSupplierContactModel';
import SupplierModel                                       from '../models/SupplierModel';
import UserModel                                           from '../models/fondationsModels/UserModel';
import EntityModel                                         from '../models/fondationsModels/EntityModel';
import { getUniqueDefinedValues }                          from '../tools/jsTools';
import ExpensePaymentVoucherStepModel                      from '../models/ExpensePaymentVoucherStepModel';
import ExpensePaymentVoucherAccountingDocumentExpenseModel
														   from '../models/ExpensePaymentVoucherAccountingDocumentExpenseModel';

export const getOrganigramEntities = (
	expense: ExpenseModel | null | undefined,
	projectCollection: ApiCollection,
	projectSubCategoryCollection: ApiCollection,
	projectCategoryCollection: ApiCollection,
	projectPoleCollection: ApiCollection,
) => {
	const project = projectCollection.find(
		(p: ProjectModel) => p.id.toString() === expense?.projectId
	) as ProjectModel;

	const projectSubCategory = projectSubCategoryCollection.find(
		(psc: ProjectSubCategoryModel) => psc.id.toString() === project?.projectSubCategoryId
	) as ProjectSubCategoryModel;

	const projectCategory = projectCategoryCollection.find(
		(pc: ProjectCategoryModel) => pc.id.toString() === projectSubCategory?.projectCategoryId
	) as ProjectCategoryModel;

	const projectPole = projectPoleCollection.find(
		(pp: ProjectPoleModel) => pp.id.toString() === projectCategory?.projectPoleId
	) as ProjectPoleModel;

	return {
		project,
		projectCategory,
		projectPole,
		projectSubCategory,
	};
};

class AbstractSplitViewStore extends AbstractStore {

	@observable public accountingDocumentCollection = new ApiCollection(AccountingDocumentModel);
	@observable public accountingDocumentExpenseCollection = new ApiCollection(AccountingDocumentExpenseModel);
	@observable public accountingDocumentExpenseCollectionPaged = new PagedCollection(AccountingDocumentExpenseModel);
	@observable public accountingDocumentMediaCollection = new ApiCollection(AccountingDocumentMediaModel);

	@observable public company = new CompanyModel();

	@observable public currentExpense = new ExpenseModel();

	@observable public expenseGroup = new ExpenseGroupModel();

	@observable public expenseDocumentCollection = new PagedCollection(ExpenseMediaModel);
	@observable public expenseEventCollection = new ApiCollection(ExpenseEventModel);
	@observable public expenseHistoryCollection = new PagedCollection(ExpenseHistoryModel);
	@observable public expensePaymentVoucherAccountingDocumentExpenseCollection = new ApiCollection(ExpensePaymentVoucherAccountingDocumentExpenseModel);
	@observable public expensePaymentVoucherCollection = new ApiCollection(ExpensePaymentVoucherModel);
	@observable public expensePaymentVoucherExpenseEventCollection = new ApiCollection(ExpenseEventModel);
	@observable public expensePaymentVoucherStepCollection = new ApiCollection(ExpensePaymentVoucherStepModel);
	@observable public expensePurchaseOrderStepCollection = new ApiCollection(ExpensePurchaseOrderStepModel);
	@observable public expenseSupplierContactCollection = new ApiCollection(ExpenseSupplierContactModel);
	@observable public expenseUserCollection = new UserApiCollection();

	@observable public project = new ProjectModel();
	@observable public projectCategory = new ProjectCategoryModel();
	@observable public projectCategoryCollection = new ApiCollection(ProjectCategoryModel);
	@observable public projectCollection = new ApiCollection(ProjectModel);
	@observable public projectPole = new ProjectPoleModel();
	@observable public projectPoleCollection = new ApiCollection(ProjectPoleModel);
	@observable public projectSubCategory = new ProjectSubCategoryModel();
	@observable public projectSubCategoryCollection = new ApiCollection(ProjectSubCategoryModel);

	@observable public entityCollection = new ApiCollection(EntityModel);
	@observable public supplierCollection = new ApiCollection(SupplierModel);
	@observable public fondationsUserCollection = new ApiCollection(UserModel);

	constructor() {
		super();

		this.expensePaymentVoucherCollection.addSort('createdAt', false);
	}

	@action
	public fetchData = async (expense: ExpenseModel) => {
		this.setCurrentExpense(expense);
		await this.currentExpense.fetch();

		await Promise.all([
			this.loadAccountingDocumentsCollections(),

			this.loadCollections()
				.then(async () => {
					await this.loadCurrentExpenseUserCollection();
				}),

			this.loadCurrentExpenseOrganigramEntities(),

			this.loadCurrentExpenseSupplier(),
		]);
	}

	@action
	public resetCollectionsFiltersForSplitViewConstructor = () => {
		this.projectCollection.clearFilters();
		this.projectSubCategoryCollection.clearFilters();
		this.projectCategoryCollection.clearFilters();
		this.projectPoleCollection.clearFilters();
	}

	@action
	public currentDashboardUpdate = async () => {
		await Promise.all([
			this.currentExpense.fetch(),
			this.expenseEventCollection.list(),
			this.expenseHistoryCollection.list(),
			this.expensePurchaseOrderStepCollection.list(),
			this.loadVouchersCollections(),
		]);
	}

	@action
	public setCurrentExpense = (expense: ExpenseModel) => {
		this.currentExpense = expense;
	}

	@action
	public loadCollections = async (): Promise<void> => {
		await Promise.all([
			this.loadAccountingDocumentsCollections(),

			this.loadVouchersCollections(),

			this.expenseDocumentCollection
				.addSort('createdAt', false)
				.setFilter('expense', this.currentExpense.id)
				.list(),

			this.expenseEventCollection
				.addSort('createdAt', false)
				.setFilter('expense', this.currentExpense.id)
				.list(),

			this.expenseHistoryCollection
				.addSort('createdAt', false)
				.setFilter('expense', this.currentExpense.id)
				.setLimit(10)
				.list(),

			this.expensePurchaseOrderStepCollection
				.setFilter('expense', this.currentExpense.id)
				.list(),
		]);
	}

	@action
	public loadVouchersCollections = async (): Promise<void> => {
		await this.expensePaymentVoucherCollection
			.setFilter('expense', this.currentExpense.id)
			.list();

		const expensePaymentVouchersIds = getUniqueDefinedValues(this.expensePaymentVoucherCollection.map(epv => epv.id));

		if (expensePaymentVouchersIds.length) {
			await Promise.all([
				this.expensePaymentVoucherAccountingDocumentExpenseCollection
					.setFilter('expensePaymentVoucher', expensePaymentVouchersIds)
					.list(),

				this.expensePaymentVoucherStepCollection
					.setFilter('expensePaymentVoucher', expensePaymentVouchersIds)
					.list(),

				this.expensePaymentVoucherExpenseEventCollection
					.setFilter('expensePaymentVoucher', expensePaymentVouchersIds)
					.addSort('createdAt', false)
					.list(),
			]);
		}

	}

	@action
	public loadAccountingDocumentsCollections = async (): Promise<void> => {
		await Promise.all([
			this.accountingDocumentCollection
				.setFilter('accountingDocumentExpenses.expense', this.currentExpense.id)
				.setFilter('groups', ['public_listing'])
				.list(),

			this.accountingDocumentMediaCollection
				.setFilter(
					'accountingDocument.accountingDocumentExpenses.expense',
					this.currentExpense.id
				)
				.list(),

			this.accountingDocumentExpenseCollection
				.setFilter('expense', this.currentExpense.id)
				.addSort('createdAt', false)
				.list(),

			this.accountingDocumentExpenseCollectionPaged
				.setFilter('expense', this.currentExpense.id)
				.addSort('createdAt', false)
				.setPage(1)
				.list(),
		]);
	}

	@action
	public loadCurrentExpenseOrganigramEntities = async (): Promise<void> => {
		await this.project
			.set('id', this.currentExpense.projectId)
			.fetch();

		await Promise.all([
			this.expenseGroup
				.set('id', this.project.expenseGroupId)
				.fetch(),

			this.projectSubCategory
				.set('id', this.project.projectSubCategoryId)
				.fetch(),
		]);

		await this.projectCategory
			.set('id', this.projectSubCategory.projectCategoryId)
			.fetch();

		await this.projectPole
			.set('id', this.projectCategory.projectPoleId)
			.fetch();

		await this.company
			.set('id', this.projectPole.companyId)
			.fetch();
	}

	@action
	public loadCurrentExpenseSupplier = async (): Promise<void> => {
		await this.expenseSupplierContactCollection
			.setFilter('expense', this.currentExpense.id)
			.list();

		const entitiesIds = getUniqueDefinedValues(this.expenseSupplierContactCollection.map((m: ExpenseSupplierContactModel) => m.entityId));
		const suppliersIds = getUniqueDefinedValues(this.expenseSupplierContactCollection.map((m: ExpenseSupplierContactModel) => m.supplierId));
		const usersIds = getUniqueDefinedValues(this.expenseSupplierContactCollection.map((m: ExpenseSupplierContactModel) => m.userId));

		const promises = [];

		if (entitiesIds.length) {
			promises.push(
				this.entityCollection
					.setFilter('id', entitiesIds)
					.list(),
			);
		}

		if (suppliersIds.length) {
			promises.push(
				this.supplierCollection
					.addSort('company', true)
					.setFilter('id', suppliersIds)
					.list(),
			);
		}

		if (usersIds.length) {
			promises.push(
				this.fondationsUserCollection
					.setFilter('id', usersIds)
					.list(),
			);
		}

		await Promise.all(promises);
	}

	@action
	public loadCurrentExpenseUserCollection = async (): Promise<void> => {
		const usersIds = [];

		usersIds.push(...this.currentExpense.currentResponsiblesIds);

		this.expenseHistoryCollection.forEach((expenseHistory: ExpenseHistoryModel) => {
			usersIds.push(
				expenseHistory.get('createdBy.id'),
				expenseHistory.get('responsible.id'),
				expenseHistory.get('applicant.id'),
			);
		});

		const uniqueDefinedUsersIds = getUniqueDefinedValues(usersIds);

		if (uniqueDefinedUsersIds.length) {
			await this.expenseUserCollection
				.setFilter('id', uniqueDefinedUsersIds)
				.list();
		}
	}

	@computed
	public get expenseSupplierContact(): ExpenseSupplierContactModel {
		let expenseSupplierContact = this.expenseSupplierContactCollection.find(
			(esc: ExpenseSupplierContactModel) => esc.expenseId === this.currentExpense.id
		) as ExpenseSupplierContactModel;

		if (!expenseSupplierContact) {
			expenseSupplierContact = new ExpenseSupplierContactModel();
		}

		return expenseSupplierContact;
	}

	@computed
	public get supplier(): SupplierModel {
		let supplier = this.supplierCollection.find(
			(s: SupplierModel) => s.id === this.expenseSupplierContact.supplierId
		) as SupplierModel;

		if (!supplier) {
			supplier = new SupplierModel();
		}

		return supplier;
	}

	@computed
	public get user(): UserModel {
		let user = this.fondationsUserCollection.find(
			(u: UserModel) => u.id.toString() === this.expenseSupplierContact.userId
		) as UserModel;

		if (!user) {
			user = new UserModel();
		}

		return user;
	}

	@computed
	public get entity(): EntityModel {
		let entity = this.entityCollection.find(
			(e: EntityModel) => e.id.toString() === this.expenseSupplierContact.entityId
		) as EntityModel;

		if (!entity) {
			entity = new EntityModel();
		}

		return entity;
	}
}

export default AbstractSplitViewStore;
