import LsdApiModel from './global/LsdApiModel';
import { computed } from 'mobx';

export default class AccountingDocumentVatModel extends LsdApiModel {
	static get path(): string {
		return '/accounting_document_vats/{?id}';
	}

	@computed
	public get amountExclTaxes() {
		return this.get('amountExclTaxes', 0);
	}

	@computed
	public get vatIri() {
		return this.get('vat');
	}
}
