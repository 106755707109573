import { observable }                                      from 'mobx';
import {
	ApiCollection,
	SplitViewCollection,
}                                                          from 'modelx-jts-common';
import AbstractSplitViewStore                              from './AbstractSplitViewStore';
import AccountingDocumentExpenseModel                      from '../models/AccountingDocumentExpenseModel';
import AccountingDocumentModel                             from '../models/AccountingDocumentModel';
import ExpenseModel                                        from '../models/ExpenseModel';
import ExpensePaymentVoucherIdModel                        from '../models/ExpensePaymentVoucherIdModel';
import ExpensePaymentVoucherModel                          from '../models/ExpensePaymentVoucherModel';
import UserApiCollection                                   from '../models/collections/UserApiCollection';
import ExpensePaymentVoucherAccountingDocumentExpenseModel from '../models/ExpensePaymentVoucherAccountingDocumentExpenseModel';
import ExpenseSupplierContactModel                         from '../models/ExpenseSupplierContactModel';
import SupplierModel                                       from '../models/SupplierModel';
import EntityModel                                         from '../models/fondationsModels/EntityModel';
import { action }                                          from 'mobx';
import { getUniqueDefinedValues }                          from 'tools/jsTools';

class PaymentVoucherSplitViewStore extends AbstractSplitViewStore {
	@observable public splitViewAccountingDocumentCollection = new ApiCollection(AccountingDocumentModel);
	@observable public splitViewAccountingDocumentExpenseCollection = new ApiCollection(AccountingDocumentExpenseModel);
	@observable public splitViewEntityCollection = new ApiCollection(EntityModel);
	@observable public splitViewExpenseCollection = new ApiCollection(ExpenseModel);
	@observable public splitViewExpensePaymentVoucherAccountingDocumentExpenseCollection = new ApiCollection(ExpensePaymentVoucherAccountingDocumentExpenseModel);
	@observable public splitViewExpensePaymentVoucherCollection = new SplitViewCollection(ExpensePaymentVoucherModel, ExpensePaymentVoucherIdModel);
	@observable public splitViewExpenseSupplierCollection = new ApiCollection(SupplierModel);
	@observable public splitViewExpenseSupplierContactCollection = new ApiCollection(ExpenseSupplierContactModel);
	@observable public splitViewUserCollection = new UserApiCollection();

	@action
	public loadSplitViewAccountingDocumentsCollections  = async () => {
		this.splitViewExpensePaymentVoucherAccountingDocumentExpenseCollection.clear();
		this.splitViewAccountingDocumentExpenseCollection.clear();
		this.splitViewAccountingDocumentCollection.clear();

		const vouchersIds = getUniqueDefinedValues(
			this.splitViewExpensePaymentVoucherCollection.pagedCollection.map((voucher: ExpensePaymentVoucherModel) => voucher.id),
		);

		if (vouchersIds.length < 1) {
			return;
		}

		await this.splitViewExpensePaymentVoucherAccountingDocumentExpenseCollection
			.setFilter('expensePaymentVoucher', vouchersIds)
			.list();

		const accountingDocumentExpensesIds = getUniqueDefinedValues(
			this.splitViewExpensePaymentVoucherAccountingDocumentExpenseCollection.map((pvAde: ExpensePaymentVoucherAccountingDocumentExpenseModel) => pvAde.accountingDocumentExpenseId),
		);

		if (accountingDocumentExpensesIds.length < 1) {
			return;
		}

		await this.splitViewAccountingDocumentExpenseCollection
			.setFilter('id', accountingDocumentExpensesIds)
			.list();

		const accountingDocumentsIds = getUniqueDefinedValues(
			this.splitViewAccountingDocumentExpenseCollection.map((ade: AccountingDocumentExpenseModel) => ade.accountingDocumentId),
		);

		if (accountingDocumentsIds.length < 1) {
			return;
		}

		await this.splitViewAccountingDocumentCollection
			.setFilter('id', accountingDocumentsIds)
			.list();
	}

	@action
	public loadSplitViewExpensesCollections = async () => {
		this.splitViewExpenseCollection.clear();
		this.splitViewExpenseSupplierContactCollection.clear();
		this.splitViewEntityCollection.clear();

		const expensesIds = getUniqueDefinedValues(
			this.splitViewExpensePaymentVoucherCollection.pagedCollection.map((voucher: ExpensePaymentVoucherModel) => voucher.expenseId),
		);


		if (expensesIds.length < 1) {
			return;
		}

		await Promise.all([
			this.splitViewExpenseCollection
				.setFilters({id: expensesIds})
				.list(),

			this.splitViewExpenseSupplierContactCollection
				.setFilter('expense', expensesIds)
				.list(),
		]);

		const entitiesIds = getUniqueDefinedValues(
			this.splitViewExpenseSupplierContactCollection.map((esc: ExpenseSupplierContactModel) => esc.entityId)
		);

		if (entitiesIds.length < 1) {
			return;
		}

		await this.splitViewEntityCollection
			.setFilter('id', entitiesIds)
			.list();
	}

	@action
	public loadSplitViewUserCollection = async () => {
		this.splitViewUserCollection.clear();

		const usersIds = getUniqueDefinedValues(
			this.splitViewExpensePaymentVoucherCollection.pagedCollection.map((voucher: ExpensePaymentVoucherModel) => voucher.currentResponsibleId),
		);

		if (usersIds.length < 1) {
			return;
		}

		await this.splitViewUserCollection
				.setFilter('id', usersIds)
				.list();
	}
}

export default new PaymentVoucherSplitViewStore();
