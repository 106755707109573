import LsdApiModel  from './global/LsdApiModel';
import { computed } from 'mobx';

export default class AccountingDocumentDeadlineModel extends LsdApiModel {
	static get path(): string {
		return '/accounting_document_deadlines/{?id}';
	}

	@computed
	public get amount() {
		return this.get('amount');
	}

	@computed
	public get deadline() {
		return this.get('deadline');
	}
}
