import LsdApiModel from './global/LsdApiModel';
import {computed} from "mobx";

export default class CompanyPurchaseOrderStepModel extends LsdApiModel {
	static get path(): string {
		return '/company_purchase_order_steps/{?id}';
	}

	@computed
	get purchaseOrderStepReference() {
		return this.get('purchaseOrderStep.reference');
	}
}
