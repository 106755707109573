import { ApiCollection } from 'modelx-jts-common';
import CurrencyModel     from '../models/CurrencyModel';

export const currencySymbols = {
	EUR: '€',
	USD: '$',
	JPY: '¥'
};

export const convertFromCurrencyToCurrency = (
	fromCurrency: string | CurrencyModel,
	toCurrency: string | CurrencyModel,
	currencyCollection: ApiCollection = undefined,
	value: number = 0,
) => {
	if (!fromCurrency || !toCurrency) {
		return 0;
	}

	if (typeof fromCurrency === 'string') {
		fromCurrency = currencyCollection?.find((c: CurrencyModel) => c.iri === fromCurrency) as CurrencyModel;
	}

	if (typeof toCurrency === 'string') {
		toCurrency = currencyCollection?.find((c: CurrencyModel) => c.iri === toCurrency) as CurrencyModel;
	}

	const amountInEuro = convertCurrencyToEuro(value, fromCurrency);

	return convertEuroToCurrency(amountInEuro, toCurrency);
};

export const convertEuroToCurrency = (value: number, currency: CurrencyModel | undefined): number => {
	if (!!currency) {
		return value / currency.eurRate;
	}

	return value;
};

export const convertCurrencyToEuro = (value: number, currency: CurrencyModel | undefined): number => {
	if (!!currency) {
		return value * currency.eurRate;
	}

	return value;
};

export const twoDecimals = (data: number) => {
	return Number.parseFloat((Math.round(data * 100) / 100).toFixed(2));
};

export const noDecimal = (data: number) => {
	return Number.parseFloat((Math.round(data * 100) / 100).toFixed(0));
};

export const formatPriceFromCurrency = (
	value: number,
	currency: CurrencyModel,
	locale: string = 'fr-FR',
	options: any = {},
): string => {
		return formatPrice(value, currency && currency.get('code'), locale, options);
	};

export const formatPrice = (
	value: number,
	currencyCode?: string,
	locale: string = 'fr-FR',
	options: any = {},
): string => {
	if (Math.abs(value) < 0.01) {
		value = 0;
	}

	if (currencyCode === 'JPY') {
		options.minimumFractionDigits = 0;
		options.maximumFractionDigits = 0;
	}

	const str = new Intl.NumberFormat(locale, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		...options
	}).format(value);

	if (currencyCode === 'USD') {
		return `${currencySymbols[currencyCode]} ${str}`;
	}

	if (currencyCode) {
		return `${str} ${currencySymbols[currencyCode]}`;
	}

	return str;
};

export const formatPriceNumber = (
	value: number,
	currencyCode?: string,
	locale: string = 'fr-FR',
	options: any = {},
): number => {
	if (Math.abs(value) < 0.01) {
		value = 0;
	}

	if (currencyCode === 'JPY') {
		options.minimumFractionDigits = 0;
		options.maximumFractionDigits = 0;
	}

	const str = new Intl.NumberFormat(locale, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		...options
	}).format(value);

	const re = /\s*(;|$)\s*/g;

	const strAsArray = str
		.replace(re, '')
		.replace(',', '.')
		.split('')
		.map(x => {
			if (x.charCodeAt(0) !== 8239 && x.charCodeAt(0) !== 160) {
				return x;
			}
		});

	const strWithoutSpaces = strAsArray.join('');

	return Number.parseFloat(strWithoutSpaces);
};

export default {
	convertCurrencyToEuro,
	convertEuroToCurrency,
	convertFromCurrencyToCurrency,
	noDecimal,
	twoDecimals,
};
