import LsdApiModel  from './global/LsdApiModel';
import { computed } from 'mobx';

export default class ExpenseEventModel extends LsdApiModel {
	static get path(): string {
		return '/expense_events/{?id}';
	}

	@computed
	public get expensePaymentVoucherId() {
		return this.get('expensePaymentVoucher.id');
	}

	@computed
	public get toUserId() {
		return this.get('toUser.id');
	}
}
