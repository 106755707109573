import LsdApiModel from './global/LsdApiModel';
import {computed} from 'mobx';

export default class ExpensePurchaseOrderStepModel extends LsdApiModel {
	static get path(): string {
		return '/expense_purchase_order_steps/{?id}';
	}

	@computed
	public get companyPurchaseOrderStepId() {
		return this.get('companyPurchaseOrderStep.id');
	}

	@computed
	public get responsibleIri() {
		return this.get('responsible.@id');
	}

	@computed
	public get responsibleId() {
		return this.get('responsible.id');
	}

	@computed
	public get refusedById() {
		return this.get('refusedBy.id');
	}
}
